/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * CieApi (application/caml+json)
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey,
} from 'react-query'
import type {
  ApiSharedSearchResponseOfApiSearchResultModel,
  ApiRequestModel,
  ApiSharedSearchResponseOfEventDataViewModel,
  SearchRequestModelBody,
  CouncilViewModel,
  GetCouncilByLocationParams,
  CouncilSearchTermViewModel,
  SearchCouncilsParams,
  ApiListResponseOfGeographyMapLiteModel,
  ApiResponseOfGeographyMapLiteModel,
  LocalitySearchViewModel,
  GetLcalityByLatLngParams,
  ApiListResponseOfLocalitySearchViewModel,
  LocalitiesSearchRequestBody,
  SearchLocalitiesParams,
  ApiResponseOfLocalitySearchViewModel,
  StatesModel,
  ApiResponseOfString,
} from '../models'
import searchDirectoryMutator, {
  ErrorType as SearchDirectoryErrorType,
} from '../mutator/cie-api-axios.mutator'
import searchDiaryMutator, {
  ErrorType as SearchDiaryErrorType,
} from '../mutator/cie-api-axios.mutator'
import getCouncilMutator, {
  ErrorType as GetCouncilErrorType,
} from '../mutator/cie-api-axios.mutator'
import getCouncilByLocationMutator, {
  ErrorType as GetCouncilByLocationErrorType,
} from '../mutator/cie-api-axios.mutator'
import getCouncilByStateNameMutator, {
  ErrorType as GetCouncilByStateNameErrorType,
} from '../mutator/cie-api-axios.mutator'
import searchCouncilsMutator, {
  ErrorType as SearchCouncilsErrorType,
} from '../mutator/cie-api-axios.mutator'
import getCouncilByLocalityIdMutator, {
  ErrorType as GetCouncilByLocalityIdErrorType,
} from '../mutator/cie-api-axios.mutator'
import getGeographiesBySetMutator, {
  ErrorType as GetGeographiesBySetErrorType,
} from '../mutator/cie-api-axios.mutator'
import getGeographyByIdMutator, {
  ErrorType as GetGeographyByIdErrorType,
} from '../mutator/cie-api-axios.mutator'
import getIntersectionsByLatLngMutator, {
  ErrorType as GetIntersectionsByLatLngErrorType,
} from '../mutator/cie-api-axios.mutator'
import getLcalityByLatLngMutator, {
  ErrorType as GetLcalityByLatLngErrorType,
} from '../mutator/cie-api-axios.mutator'
import searchLocalitiesMutator, {
  ErrorType as SearchLocalitiesErrorType,
} from '../mutator/cie-api-axios.mutator'
import getLocalitiesByStateCouncilMutator, {
  ErrorType as GetLocalitiesByStateCouncilErrorType,
} from '../mutator/cie-api-axios.mutator'
import localityByParamsMutator, {
  ErrorType as LocalityByParamsErrorType,
} from '../mutator/cie-api-axios.mutator'
import getStatesMutator, {
  ErrorType as GetStatesErrorType,
} from '../mutator/cie-api-axios.mutator'
import getStateByNameMutator, {
  ErrorType as GetStateByNameErrorType,
} from '../mutator/cie-api-axios.mutator'
import getHealthEngineNextAppointmentMutator, {
  ErrorType as GetHealthEngineNextAppointmentErrorType,
} from '../mutator/cie-api-axios.mutator'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

export const searchDirectory = (apiRequestModel: ApiRequestModel) => {
  return searchDirectoryMutator<ApiSharedSearchResponseOfApiSearchResultModel>({
    url: `/api/v1/directory/search`,
    method: 'post',
    data: apiRequestModel,
  })
}

export const getSearchDirectoryQueryKey = (
  apiRequestModel: ApiRequestModel
) => [`/api/v1/directory/search`, apiRequestModel]

export const useSearchDirectoryInfinite = <
  TData = AsyncReturnType<typeof searchDirectory>,
  TError = SearchDirectoryErrorType<unknown>
>(
  apiRequestModel: ApiRequestModel,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof searchDirectory>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchDirectoryQueryKey(apiRequestModel)

  const queryFn: QueryFunction<AsyncReturnType<typeof searchDirectory>> = () =>
    searchDirectory(apiRequestModel)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof searchDirectory>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}

export const useSearchDirectory = <
  TData = AsyncReturnType<typeof searchDirectory>,
  TError = SearchDirectoryErrorType<unknown>
>(
  apiRequestModel: ApiRequestModel,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof searchDirectory>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchDirectoryQueryKey(apiRequestModel)

  const queryFn: QueryFunction<AsyncReturnType<typeof searchDirectory>> = () =>
    searchDirectory(apiRequestModel)

  const query = useQuery<
    AsyncReturnType<typeof searchDirectory>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}

export const searchDiary = (searchRequestModelBody: SearchRequestModelBody) => {
  return searchDiaryMutator<ApiSharedSearchResponseOfEventDataViewModel>({
    url: `/api/v1/diary/search`,
    method: 'post',
    data: searchRequestModelBody,
  })
}

export const getSearchDiaryQueryKey = (
  searchRequestModelBody: SearchRequestModelBody
) => [`/api/v1/diary/search`, searchRequestModelBody]

export const useSearchDiaryInfinite = <
  TData = AsyncReturnType<typeof searchDiary>,
  TError = SearchDiaryErrorType<unknown>
>(
  searchRequestModelBody: SearchRequestModelBody,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof searchDiary>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchDiaryQueryKey(searchRequestModelBody)

  const queryFn: QueryFunction<AsyncReturnType<typeof searchDiary>> = () =>
    searchDiary(searchRequestModelBody)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof searchDiary>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}

export const useSearchDiary = <
  TData = AsyncReturnType<typeof searchDiary>,
  TError = SearchDiaryErrorType<unknown>
>(
  searchRequestModelBody: SearchRequestModelBody,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof searchDiary>, TError, TData>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchDiaryQueryKey(searchRequestModelBody)

  const queryFn: QueryFunction<AsyncReturnType<typeof searchDiary>> = () =>
    searchDiary(searchRequestModelBody)

  const query = useQuery<AsyncReturnType<typeof searchDiary>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  )

  return {
    queryKey,
    ...query,
  }
}

/**
 * @deprecated
 */
export const getCouncil = (councilId: number) => {
  return getCouncilMutator<CouncilViewModel>({
    url: `/api/v1/system/councils/${councilId}`,
    method: 'get',
  })
}

export const getGetCouncilQueryKey = (councilId: number) => [
  `/api/v1/system/councils/${councilId}`,
]

export const useGetCouncilInfinite = <
  TData = AsyncReturnType<typeof getCouncil>,
  TError = GetCouncilErrorType<unknown>
>(
  councilId: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getCouncil>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCouncilQueryKey(councilId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getCouncil>> = () =>
    getCouncil(councilId)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getCouncil>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!councilId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetCouncil = <
  TData = AsyncReturnType<typeof getCouncil>,
  TError = GetCouncilErrorType<unknown>
>(
  councilId: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getCouncil>, TError, TData>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCouncilQueryKey(councilId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getCouncil>> = () =>
    getCouncil(councilId)

  const query = useQuery<AsyncReturnType<typeof getCouncil>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!councilId, ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

/**
 * @deprecated
 */
export const getCouncilByLocation = (
  lat: number,
  lng: number,
  params?: GetCouncilByLocationParams
) => {
  return getCouncilByLocationMutator<CouncilViewModel>({
    url: `/api/v1/system/councils/${lat}/${lng}`,
    method: 'get',
    params,
  })
}

export const getGetCouncilByLocationQueryKey = (
  lat: number,
  lng: number,
  params?: GetCouncilByLocationParams
) => [`/api/v1/system/councils/${lat}/${lng}`, ...(params ? [params] : [])]

export const useGetCouncilByLocationInfinite = <
  TData = AsyncReturnType<typeof getCouncilByLocation>,
  TError = GetCouncilByLocationErrorType<unknown>
>(
  lat: number,
  lng: number,
  params?: GetCouncilByLocationParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getCouncilByLocation>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetCouncilByLocationQueryKey(lat, lng, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getCouncilByLocation>
  > = () => getCouncilByLocation(lat, lng, params)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getCouncilByLocation>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(lat && lng), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetCouncilByLocation = <
  TData = AsyncReturnType<typeof getCouncilByLocation>,
  TError = GetCouncilByLocationErrorType<unknown>
>(
  lat: number,
  lng: number,
  params?: GetCouncilByLocationParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getCouncilByLocation>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetCouncilByLocationQueryKey(lat, lng, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getCouncilByLocation>
  > = () => getCouncilByLocation(lat, lng, params)

  const query = useQuery<
    AsyncReturnType<typeof getCouncilByLocation>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(lat && lng), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getCouncilByStateName = (state: string, name: string) => {
  return getCouncilByStateNameMutator<CouncilViewModel>({
    url: `/api/v1/system/councils/name/${state}/${name}`,
    method: 'get',
  })
}

export const getGetCouncilByStateNameQueryKey = (
  state: string,
  name: string
) => [`/api/v1/system/councils/name/${state}/${name}`]

export const useGetCouncilByStateNameInfinite = <
  TData = AsyncReturnType<typeof getCouncilByStateName>,
  TError = GetCouncilByStateNameErrorType<unknown>
>(
  state: string,
  name: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getCouncilByStateName>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetCouncilByStateNameQueryKey(state, name)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getCouncilByStateName>
  > = () => getCouncilByStateName(state, name)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getCouncilByStateName>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(state && name), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetCouncilByStateName = <
  TData = AsyncReturnType<typeof getCouncilByStateName>,
  TError = GetCouncilByStateNameErrorType<unknown>
>(
  state: string,
  name: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getCouncilByStateName>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetCouncilByStateNameQueryKey(state, name)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getCouncilByStateName>
  > = () => getCouncilByStateName(state, name)

  const query = useQuery<
    AsyncReturnType<typeof getCouncilByStateName>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(state && name), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

/**
 * @deprecated
 */
export const searchCouncils = (
  searchTerm: string,
  params?: SearchCouncilsParams
) => {
  return searchCouncilsMutator<CouncilSearchTermViewModel[]>({
    url: `/api/v1/system/councils/search/${searchTerm}`,
    method: 'post',
    params,
  })
}

export const useSearchCouncils = <
  TError = SearchCouncilsErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof searchCouncils>,
    TError,
    { searchTerm: string; params?: SearchCouncilsParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof searchCouncils>,
    { searchTerm: string; params?: SearchCouncilsParams }
  > = (props) => {
    const { searchTerm, params } = props || {}

    return searchCouncils(searchTerm, params)
  }

  return useMutation<
    AsyncReturnType<typeof searchCouncils>,
    TError,
    { searchTerm: string; params?: SearchCouncilsParams },
    TContext
  >(mutationFn, mutationOptions)
}

export const getCouncilByLocalityId = (id: number) => {
  return getCouncilByLocalityIdMutator<CouncilViewModel>({
    url: `/api/v1/system/councils/locality/${id}`,
    method: 'get',
  })
}

export const getGetCouncilByLocalityIdQueryKey = (id: number) => [
  `/api/v1/system/councils/locality/${id}`,
]

export const useGetCouncilByLocalityIdInfinite = <
  TData = AsyncReturnType<typeof getCouncilByLocalityId>,
  TError = GetCouncilByLocalityIdErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getCouncilByLocalityId>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetCouncilByLocalityIdQueryKey(id)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getCouncilByLocalityId>
  > = () => getCouncilByLocalityId(id)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getCouncilByLocalityId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetCouncilByLocalityId = <
  TData = AsyncReturnType<typeof getCouncilByLocalityId>,
  TError = GetCouncilByLocalityIdErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getCouncilByLocalityId>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetCouncilByLocalityIdQueryKey(id)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getCouncilByLocalityId>
  > = () => getCouncilByLocalityId(id)

  const query = useQuery<
    AsyncReturnType<typeof getCouncilByLocalityId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

/**
 * @deprecated
 */
export const getGeographiesBySet = (dataset: string) => {
  return getGeographiesBySetMutator<ApiListResponseOfGeographyMapLiteModel>({
    url: `/api/v1/system/geographies/${dataset}`,
    method: 'get',
  })
}

export const getGetGeographiesBySetQueryKey = (dataset: string) => [
  `/api/v1/system/geographies/${dataset}`,
]

export const useGetGeographiesBySetInfinite = <
  TData = AsyncReturnType<typeof getGeographiesBySet>,
  TError = GetGeographiesBySetErrorType<unknown>
>(
  dataset: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getGeographiesBySet>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetGeographiesBySetQueryKey(dataset)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getGeographiesBySet>
  > = () => getGeographiesBySet(dataset)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getGeographiesBySet>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!dataset, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetGeographiesBySet = <
  TData = AsyncReturnType<typeof getGeographiesBySet>,
  TError = GetGeographiesBySetErrorType<unknown>
>(
  dataset: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getGeographiesBySet>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetGeographiesBySetQueryKey(dataset)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getGeographiesBySet>
  > = () => getGeographiesBySet(dataset)

  const query = useQuery<
    AsyncReturnType<typeof getGeographiesBySet>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!dataset, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

/**
 * @deprecated
 */
export const getGeographyById = (id: number) => {
  return getGeographyByIdMutator<ApiResponseOfGeographyMapLiteModel>({
    url: `/api/v1/system/geographies/id/${id}`,
    method: 'get',
  })
}

export const getGetGeographyByIdQueryKey = (id: number) => [
  `/api/v1/system/geographies/id/${id}`,
]

export const useGetGeographyByIdInfinite = <
  TData = AsyncReturnType<typeof getGeographyById>,
  TError = GetGeographyByIdErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getGeographyById>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetGeographyByIdQueryKey(id)

  const queryFn: QueryFunction<AsyncReturnType<typeof getGeographyById>> = () =>
    getGeographyById(id)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getGeographyById>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetGeographyById = <
  TData = AsyncReturnType<typeof getGeographyById>,
  TError = GetGeographyByIdErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getGeographyById>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetGeographyByIdQueryKey(id)

  const queryFn: QueryFunction<AsyncReturnType<typeof getGeographyById>> = () =>
    getGeographyById(id)

  const query = useQuery<
    AsyncReturnType<typeof getGeographyById>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

/**
 * @deprecated
 */
export const getIntersectionsByLatLng = (lat: number, lng: number) => {
  return getIntersectionsByLatLngMutator<unknown>({
    url: `/api/v1/system/geographies/intersections/${lat}/${lng}`,
    method: 'get',
  })
}

export const getGetIntersectionsByLatLngQueryKey = (
  lat: number,
  lng: number
) => [`/api/v1/system/geographies/intersections/${lat}/${lng}`]

export const useGetIntersectionsByLatLngInfinite = <
  TData = AsyncReturnType<typeof getIntersectionsByLatLng>,
  TError = GetIntersectionsByLatLngErrorType<unknown>
>(
  lat: number,
  lng: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getIntersectionsByLatLng>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetIntersectionsByLatLngQueryKey(lat, lng)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getIntersectionsByLatLng>
  > = () => getIntersectionsByLatLng(lat, lng)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getIntersectionsByLatLng>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(lat && lng), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetIntersectionsByLatLng = <
  TData = AsyncReturnType<typeof getIntersectionsByLatLng>,
  TError = GetIntersectionsByLatLngErrorType<unknown>
>(
  lat: number,
  lng: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getIntersectionsByLatLng>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetIntersectionsByLatLngQueryKey(lat, lng)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getIntersectionsByLatLng>
  > = () => getIntersectionsByLatLng(lat, lng)

  const query = useQuery<
    AsyncReturnType<typeof getIntersectionsByLatLng>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(lat && lng), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

/**
 * @summary Find the locality based on the supplied lat/long.
 */
export const getLcalityByLatLng = (
  lat: number,
  lng: number,
  params?: GetLcalityByLatLngParams
) => {
  return getLcalityByLatLngMutator<LocalitySearchViewModel>({
    url: `/api/v1/system/localities/${lat}/${lng}`,
    method: 'get',
    params,
  })
}

export const getGetLcalityByLatLngQueryKey = (
  lat: number,
  lng: number,
  params?: GetLcalityByLatLngParams
) => [`/api/v1/system/localities/${lat}/${lng}`, ...(params ? [params] : [])]

export const useGetLcalityByLatLngInfinite = <
  TData = AsyncReturnType<typeof getLcalityByLatLng>,
  TError = GetLcalityByLatLngErrorType<unknown>
>(
  lat: number,
  lng: number,
  params?: GetLcalityByLatLngParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getLcalityByLatLng>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetLcalityByLatLngQueryKey(lat, lng, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getLcalityByLatLng>
  > = () => getLcalityByLatLng(lat, lng, params)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getLcalityByLatLng>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(lat && lng), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetLcalityByLatLng = <
  TData = AsyncReturnType<typeof getLcalityByLatLng>,
  TError = GetLcalityByLatLngErrorType<unknown>
>(
  lat: number,
  lng: number,
  params?: GetLcalityByLatLngParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getLcalityByLatLng>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetLcalityByLatLngQueryKey(lat, lng, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getLcalityByLatLng>
  > = () => getLcalityByLatLng(lat, lng, params)

  const query = useQuery<
    AsyncReturnType<typeof getLcalityByLatLng>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(lat && lng), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const searchLocalities = (
  localitiesSearchRequestBody: LocalitiesSearchRequestBody,
  params?: SearchLocalitiesParams
) => {
  return searchLocalitiesMutator<ApiListResponseOfLocalitySearchViewModel>({
    url: `/api/v1/system/localities/search`,
    method: 'post',
    data: localitiesSearchRequestBody,
    params,
  })
}

export const useSearchLocalities = <
  TError = SearchLocalitiesErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof searchLocalities>,
    TError,
    { data: LocalitiesSearchRequestBody; params?: SearchLocalitiesParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof searchLocalities>,
    { data: LocalitiesSearchRequestBody; params?: SearchLocalitiesParams }
  > = (props) => {
    const { data, params } = props || {}

    return searchLocalities(data, params)
  }

  return useMutation<
    AsyncReturnType<typeof searchLocalities>,
    TError,
    { data: LocalitiesSearchRequestBody; params?: SearchLocalitiesParams },
    TContext
  >(mutationFn, mutationOptions)
}

export const getLocalitiesByStateCouncil = (state: string, council: string) => {
  return getLocalitiesByStateCouncilMutator<ApiListResponseOfLocalitySearchViewModel>(
    {
      url: `/api/v1/system/localities/council/${state}/${council}`,
      method: 'get',
    }
  )
}

export const getGetLocalitiesByStateCouncilQueryKey = (
  state: string,
  council: string
) => [`/api/v1/system/localities/council/${state}/${council}`]

export const useGetLocalitiesByStateCouncilInfinite = <
  TData = AsyncReturnType<typeof getLocalitiesByStateCouncil>,
  TError = GetLocalitiesByStateCouncilErrorType<unknown>
>(
  state: string,
  council: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getLocalitiesByStateCouncil>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetLocalitiesByStateCouncilQueryKey(state, council)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getLocalitiesByStateCouncil>
  > = () => getLocalitiesByStateCouncil(state, council)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getLocalitiesByStateCouncil>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(state && council), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetLocalitiesByStateCouncil = <
  TData = AsyncReturnType<typeof getLocalitiesByStateCouncil>,
  TError = GetLocalitiesByStateCouncilErrorType<unknown>
>(
  state: string,
  council: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getLocalitiesByStateCouncil>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetLocalitiesByStateCouncilQueryKey(state, council)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getLocalitiesByStateCouncil>
  > = () => getLocalitiesByStateCouncil(state, council)

  const query = useQuery<
    AsyncReturnType<typeof getLocalitiesByStateCouncil>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(state && council), ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const localityByParams = (
  state: string,
  council: string,
  locality: string
) => {
  return localityByParamsMutator<ApiResponseOfLocalitySearchViewModel>({
    url: `/api/v1/system/localities/name/${state}/${council}/${locality}`,
    method: 'get',
  })
}

export const getLocalityByParamsQueryKey = (
  state: string,
  council: string,
  locality: string
) => [`/api/v1/system/localities/name/${state}/${council}/${locality}`]

export const useLocalityByParamsInfinite = <
  TData = AsyncReturnType<typeof localityByParams>,
  TError = LocalityByParamsErrorType<unknown>
>(
  state: string,
  council: string,
  locality: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof localityByParams>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getLocalityByParamsQueryKey(state, council, locality)

  const queryFn: QueryFunction<AsyncReturnType<typeof localityByParams>> = () =>
    localityByParams(state, council, locality)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof localityByParams>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(state && council && locality),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}

export const useLocalityByParams = <
  TData = AsyncReturnType<typeof localityByParams>,
  TError = LocalityByParamsErrorType<unknown>
>(
  state: string,
  council: string,
  locality: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof localityByParams>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getLocalityByParamsQueryKey(state, council, locality)

  const queryFn: QueryFunction<AsyncReturnType<typeof localityByParams>> = () =>
    localityByParams(state, council, locality)

  const query = useQuery<
    AsyncReturnType<typeof localityByParams>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(state && council && locality),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}

export const getStates = () => {
  return getStatesMutator<StatesModel[]>({
    url: `/api/v1/system/states`,
    method: 'get',
  })
}

export const getGetStatesQueryKey = () => [`/api/v1/system/states`]

export const useGetStatesInfinite = <
  TData = AsyncReturnType<typeof getStates>,
  TError = GetStatesErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<
    AsyncReturnType<typeof getStates>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStatesQueryKey()

  const queryFn: QueryFunction<AsyncReturnType<typeof getStates>> = () =>
    getStates()

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getStates>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}

export const useGetStates = <
  TData = AsyncReturnType<typeof getStates>,
  TError = GetStatesErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getStates>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStatesQueryKey()

  const queryFn: QueryFunction<AsyncReturnType<typeof getStates>> = () =>
    getStates()

  const query = useQuery<AsyncReturnType<typeof getStates>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  )

  return {
    queryKey,
    ...query,
  }
}

export const getStateByName = (name: string) => {
  return getStateByNameMutator<StatesModel>({
    url: `/api/v1/system/states/name/${name}`,
    method: 'get',
  })
}

export const getGetStateByNameQueryKey = (name: string) => [
  `/api/v1/system/states/name/${name}`,
]

export const useGetStateByNameInfinite = <
  TData = AsyncReturnType<typeof getStateByName>,
  TError = GetStateByNameErrorType<unknown>
>(
  name: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getStateByName>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStateByNameQueryKey(name)

  const queryFn: QueryFunction<AsyncReturnType<typeof getStateByName>> = () =>
    getStateByName(name)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getStateByName>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!name, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const useGetStateByName = <
  TData = AsyncReturnType<typeof getStateByName>,
  TError = GetStateByNameErrorType<unknown>
>(
  name: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getStateByName>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStateByNameQueryKey(name)

  const queryFn: QueryFunction<AsyncReturnType<typeof getStateByName>> = () =>
    getStateByName(name)

  const query = useQuery<AsyncReturnType<typeof getStateByName>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!name, ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

export const getHealthEngineNextAppointment = (
  outletId: number,
  listingId: number,
  healthEngineId: number
) => {
  return getHealthEngineNextAppointmentMutator<ApiResponseOfString>({
    url: `/api/v1/healthengine/nextappointment/${outletId}/${listingId}/${healthEngineId}`,
    method: 'get',
  })
}

export const getGetHealthEngineNextAppointmentQueryKey = (
  outletId: number,
  listingId: number,
  healthEngineId: number
) => [
  `/api/v1/healthengine/nextappointment/${outletId}/${listingId}/${healthEngineId}`,
]

export const useGetHealthEngineNextAppointmentInfinite = <
  TData = AsyncReturnType<typeof getHealthEngineNextAppointment>,
  TError = GetHealthEngineNextAppointmentErrorType<unknown>
>(
  outletId: number,
  listingId: number,
  healthEngineId: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getHealthEngineNextAppointment>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetHealthEngineNextAppointmentQueryKey(
      outletId,
      listingId,
      healthEngineId
    )

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getHealthEngineNextAppointment>
  > = () => getHealthEngineNextAppointment(outletId, listingId, healthEngineId)

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getHealthEngineNextAppointment>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(outletId && listingId && healthEngineId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}

export const useGetHealthEngineNextAppointment = <
  TData = AsyncReturnType<typeof getHealthEngineNextAppointment>,
  TError = GetHealthEngineNextAppointmentErrorType<unknown>
>(
  outletId: number,
  listingId: number,
  healthEngineId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getHealthEngineNextAppointment>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetHealthEngineNextAppointmentQueryKey(
      outletId,
      listingId,
      healthEngineId
    )

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getHealthEngineNextAppointment>
  > = () => getHealthEngineNextAppointment(outletId, listingId, healthEngineId)

  const query = useQuery<
    AsyncReturnType<typeof getHealthEngineNextAppointment>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(outletId && listingId && healthEngineId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}
